<template>
  <div
    v-if="
      getUser.role.slug === 'rh' ||
        getUser.role.slug === 'super-administrateur'
    "
  >
<b-card no-body>
      <b-card-header class="pb-50">
        <h5>Filtrages</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="10"
            lg="10"
            class="mb-md-0 mb-2"
          >
            <b-row>
              <b-col
                cols="12"
                sm="6"
                md="4"
                lg="4"
              >
                <label for="client">Professionnel</label>
                <v-select
                  v-model="payloadFilter.pro_id"
                  :options="proOptions"
                  class="invoice-filter-select"
                  label="name"
                  :reduce="(pro) => pro.id"
                >
                  <template v-slot:no-options>
                    <b-spinner
                      v-if="isProfessionalsWithoutPaginationLoading"
                      style="width: 2.5rem; height: 2.5rem"
                      class="align-middle text-info"
                    />
                    <span
                      v-else
                      class="mt-1 font-medium-1"
                    >Aucun client ne correspond</span>
                  </template>
                </v-select>
              </b-col>
              
              <b-col
                cols="12"
                sm="6"
                md="3"
                lg="3"
              >
                <label for="service">Services</label>
                <v-select
                  v-model="payloadFilter.service_id"
                  :options="serviceOptions"
                  class="invoice-filter-select"
                  label="name"
                  :reduce="(service) => service.id"
                >
                  <template v-slot:no-options>
                    <b-spinner
                      v-if="isServicesWithoutPaginationLoading"
                      style="width: 2.5rem; height: 2.5rem"
                      class="align-middle text-info"
                    />
                    <span
                      v-else
                      class="mt-1 font-medium-1"
                    >Aucun service ne correspond</span>
                  </template>
                </v-select>
              </b-col>
              <b-col
                cols="12"
                sm="6"
                md="3"
                lg="3"
              >
                <label for="status">Statut</label>
                <v-select
                  v-model="payloadFilter.status"
                  :options="statusOptions"
                  class="invoice-filter-select"
                  :reduce="(item) => item.value"
                >
                  <template #selected-option="{ label }">
                    <span class="text-truncate overflow-hidden">
                      {{ label }}
                    </span>
                  </template>
                </v-select>
              </b-col>

              <b-col
                cols="12"
                sm="6"
                md="2"
                lg="2"
                class="mt-2"
              >
                <b-button
                  variant="primary"
                  @click="applyFilterProfessionalsAction()"
                >
                  <div v-if="isReLoadFilterProfessionals">
                    <span> Chargement ... </span>
                    <b-spinner small />
                  </div>
                  <span
                    v-else
                    class="text-nowrap font-medium-1"
                  >Filtrer</span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card>
      <b-tabs>
        <b-tab
          active
          title="Liste des pros"
        >
          <div class="m-2">
            <!-- Table Top -->
            <b-row class="d-flex justify-content-between flex-wrap">
              <!-- Export pro list -->
              <!-- <b-col
                cols="12"
                class="
                  d-flex
                  align-items-center
                  justify-content-md-start justify-content-start
                  mb-1
                "
              >
                <download-excel
                  :fields="fields"
                  :data="jsonData"
                  name="liste-des-professionnels.xls"
                >
                  <b-button
                    variant="success"
                    :disabled="getProfessionals.length === 0"
                  >
                    Exporter en Excel
                  </b-button>
                </download-excel>
              </b-col> -->

              <!-- Register pro -->
              <b-col
                cols="12"
                md="3"
                class="
                  d-flex
                  align-items-center
                  justify-content-start
                  mb-1 mb-md-0
                "
              >
                <b-button
                  variant="primary"
                  :to="{ name: 'admin-pros-new' }"
                >
                  <span class="text-nowrap">Inscrire un pro</span>
                </b-button>

              </b-col>

              <!-- Actualiser -->

              <b-button
                variant="primary"
                @click="applyGetProfessionalsAction()"
              >
                <div v-if="isLoading">
                  <b-spinner small />
                </div>
                <span
                  v-else
                  class="text-nowrap font-medium-1"
                >Actualiser</span>
              </b-button>
            </b-row>
          </div>

          <vue-good-table
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            :is-loading="isProfessionalsLoading"
          >
            <template slot="emptystate">
              <div class="text-center text-info">
                <span> {{ isProfessionalsLoading ? "" : "Liste vide" }}</span>
              </div>
            </template>

            <template slot="loadingContent">
              <div class="text-center text-info my-2">
                <b-spinner class="align-middle" /> &nbsp;
              </div>
            </template>

            <template
              slot="table-row"
              slot-scope="props"
            >
              <div
                v-if="props.column.field == 'full_name' && props.row.phone_number"
                class="align-middle text-center"
              >
                <span class="d-block font-small-5 text-info text-nowrap">
                  {{ props.row.full_name }}
                </span>
                <span class="text-success font-small-4">{{
                  props.row.phone_number
                }}</span>
              </div>

              <div
                v-if="props.column.field == 'services'"
                class="align-middle text-center"
              >
                <ul>
                  <li
                    v-for="(item, index) in props.row.services"
                    :key="index"
                  >
                    <span class="text-info font-small-5">
                      {{ item.name }}
                    </span>
                  </li>
                </ul>
              </div>

              <div
                v-if="
                  props.column.field == 'invited_by' &&
                    props.row.invited_by != null
                "
                class="align-middle text-center"
              >
                <span
                  class="text-info font-small-5"
                  :class="
                    props.row.invited_by != null ? 'text-info' : 'text-danger'
                  "
                >
                  {{
                    props.row.invited_by != null
                      ? props.row.invited_by.full_name
                      : "Non assignée"
                  }}
                </span>
              </div>

              <div
                v-if="props.column.field == 'setStatus'"
                class="align-middle text-center"
              >
                <b-badge
                  pill
                  :variant="`light-${resolveProStatusVariant(
                    props.row.status
                  )}`"
                  class="text-capitalize font-small-5"
                >
                  {{ props.row.setStatus }}
                </b-badge>
              </div>

              <div v-else-if="props.column.field == 'action'">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="20"
                        class="text-info align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item
                      class="cursor-pointer itemActions"
                      @click="showProDetails(props.row)"
                    >
                      <feather-icon
                        :id="`details${props.row.id}`"
                        icon="EyeIcon"
                        class="text-info cursor-pointer mr-2"
                        size="25"
                      />
                      <span>Détails</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      class="cursor-pointer itemActions"
                      @click="applyDeleteProAction(props.row.id)"
                    >
                      <feather-icon
                        :id="`delete${props.row.id}`"
                        icon="TrashIcon"
                        class="text-info mr-1"
                        size="25"
                      />
                      <span>Supprimer</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="getUser.role.name == 'Super Administrateur'"
                      class="cursor-pointer itemActions"
                      @click="showAssignmentModal(props.row.id)"
                    >
                      <feather-icon
                        :id="`assign${props.row.id}`"
                        icon="CornerUpRightIcon"
                        class="text-info mr-1"
                        size="25"
                      />
                      <span>Assigner ce Pro</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </div>
            </template>
          </vue-good-table>

          <div class="d-flex justify-content-between mt-2 flex-wrap">
            <b-button
              :disabled="metaData.prev_page_url == null"
              variant="outline-secondary"
              @click="applyLoadMoreProfessionalsAction(metaData.prev_page_url)"
            >
              <span
                class="text-white text-nowrap font-medium-2"
              >Page précédente</span>
            </b-button>

            <div>
              <span
                class="text-white font-medium-2"
              >{{ metaData.current_page }} sur
                {{ metaData.last_page }} pages</span>
            </div>
            <div>
              <span
                class="text-white font-medium-2"
              >Total :{{ total }}</span>
            </div>
            <b-button
              variant="outline-secondary"
              :disabled="metaData.next_page_url == null"
              @click="applyLoadMoreProfessionalsAction(metaData.next_page_url)"
            >
              <span
                class="text-nowrap font-medium-1 text-white"
              >Page suivante</span>
            </b-button>
          </div>

          <b-modal
            id="modal-pro-assignment"
            cancel-variant="outline-secondary"
            hide-footer
            centered
            no-close-on-backdrop
            no-close-on-esc
            title="Assignation d'un pro"
          >
            <validation-observer
              #default="{}"
              ref="proAssignmentForm"
            >
              <!-- Form -->
              <b-form
                class="p-2"
                autocomplete="off"
                @reset.prevent="hideModal"
                @submit.prevent="applyProRegistrationAssignment"
              >
                <b-form-group
                  label="Liste des BDPs"
                  label-for="bdp"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="bdp"
                    rules="required"
                  >
                    <v-select
                      id="bdp"
                      v-model="payload.bdp_id"
                      :reduce="(bdp) => bdp.value"
                      label="label"
                      :options="bdpListOptions"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Form Actions -->
                <div class="d-flex mt-2">
                  <b-button
                    variant="primary"
                    class="mr-2"
                    type="submit"
                  >
                    <div v-if="isProRegistrationAssignmentLoading">
                      <span> Chargement ... </span>
                      <b-spinner small />
                    </div>
                    <span v-else>Assigner</span>
                  </b-button>
                  <b-button
                    type="reset"
                    variant="outline-secondary"
                  >
                    Annuler
                  </b-button>
                </div>
              </b-form>
            </validation-observer>
          </b-modal>

        </b-tab>

        <!-- <b-tab title="Pros non payés">
          <unpayed-professionals />
        </b-tab> -->

        <!-- <b-tab title="Pros payés">
          <payed-professionals />
        </b-tab> -->
      </b-tabs>
    </b-card>
  </div>
  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTab,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BCardHeader,
  BCardBody,
  BTable,
  BMedia,
  BAvatar,
  BFormGroup,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BSpinner,
  BTabs,
  BForm,
  // BAlert,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { VueGoodTable } from 'vue-good-table'
import UnpayedProfessionals from './UnpayedProfessionals.vue'
import PayedProfessionals from './PayedProfessionals.vue'

export default {
  components: {
    BTab,
    BTabs,
    BForm,
    BCard,
    ValidationProvider,
    ValidationObserver,
   BCardHeader,
  BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BSpinner,
    vSelect,
    PayedProfessionals,
    UnpayedProfessionals,
    VueGoodTable,
  },
  data() {
    return {
       payloadFilter: {
        pro_id: '',
        service_id: '',
        status: '',
      },
      isProRegistrationAssignmentLoading: false,
      isLoading: false,
      payload: {
        bdp_id: null,
      },
      total : "",
      clickedRegistrationId: null,
      bdpListOptions: [],
      isReLoadFilterProfessionals:false,
      jsonData: [],
      proOptions :[],
      bdpFilter: null,
      required,
      serviceOptions: [],
      rows: [],
      columns: [
        {
          label: 'Identité',
          field: 'full_name'
        },
        {
          label: 'Service(s)',
          field: 'services'
        },
        {
          label: 'Traité(e) par',
          field: 'invited_by'
        },
        {
          label: 'Statut',
          field: 'setStatus'
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      statusOptions: [ 
        {
          label: 'Non validé',
          value: 0,
        },
        {
          label: 'Validé',
          value: 1
        },
        {
          label: 'Suspendu',
          value: 2,
        }
      ]
    }
  },
  computed: {
    ...mapGetters('professionals', ['getProfessionals','getProfessionalsWithoutPagination']),
    ...mapGetters(['isProfessionalsLoading','isProfessionalsWithoutPaginationLoading','isServicesWithoutPaginationLoading']),
    ...mapGetters('auth', ['getUser']),
    ...mapGetters('services', ['getServicesWithoutPagination']),

    metaData() {
      const meta = {
        prev_page_url: '',
        next_page_url: '',
        current_page: '',
        last_page: '',
        current_page_url: '',
      }
      if (this.getProfessionals != null) {
        meta.prev_page_url = this.getProfessionals.prev_page_url
        meta.next_page_url = this.getProfessionals.next_page_url
        meta.current_page = this.getProfessionals.current_page
        meta.last_page = this.getProfessionals.last_page
        meta.current_page_url = `${this.getProfessionals.path}?page=${this.getProfessionals.current_page}`
        this.total = this.getProfessionals.total
      }
      return meta
    },
  },
  watch: {
    bdpFilter(newVal, oldVal) {
      if (newVal !== null) {
        this.applyGetBdpSavedProsAction(newVal)
      } else {
        this.applyGetProfessionalsAction()
      }
    },
    getProfessionals(newValue, oldValue) {
      if (oldValue.length !== 0 && oldValue.length !== newValue.length) {
        this.rows = this.customTab(this.getProfessionals.data)
      }
    },
    isProfessionalsLoading(val) {
      if (val === false) {
        this.rows = this.customTab(this.getProfessionals.data)
      }
    },
    isProfessionalsWithoutPaginationLoading(val){
      if(val == false) {
        this.getProfessionalsWithoutPagination.forEach(element => {
        this.proOptions.push({
          id: element.id,
          name: element.full_name,
        })
      })
      }
    },
    isServicesWithoutPaginationLoading(val) {
      if (val === false) {
        this.getServicesWithoutPagination.forEach(element => {
          this.serviceOptions.push({
            id: element.id,
            name: element.name,
          })
        })
      }
    },
    payloadFilter: {
      handler(newValue, oldValue) {
        const keys = Object.keys(newValue)
        keys.forEach(key => {
          if (newValue[key] == null) {
            newValue[key] = ''
          }
        })
      },
      deep: true,
    },
  },
  created() {
    this.getProfessionals.data != null
      ? (this.rows = this.customTab(this.getProfessionals.data))
      : null

    if (this.getProfessionalsWithoutPagination.length != 0) {
      this.getProfessionalsWithoutPagination.forEach(element => {
        this.proOptions.push({
          id: element.id,
          name: element.full_name,
        })
      })
    }
    if (this.getServicesWithoutPagination.length != 0) {
      this.getServicesWithoutPagination.forEach(element => {
        this.serviceOptions.push({
          id: element.id,
          name: element.name,
        })
      })
    }
  },
  mounted() {
    if (this.getUser.role.name === 'Super Administrateur') {
      this.tableColumns.splice(3, 0, {
        key: 'invited_by',
        label: 'Inscrire par',
        sortable: true,
      })
    }

   
  },
  methods: {
    ...mapActions('roles', ['getBusinessDeveloperListAction']),
    ...mapActions('professionals', [
      'getProfessionalsAction',
      'deleteProfessionalAction',
      'getBdpSavedProsAction',
      'assignProRegistrationAction',
      'loadMoreProfessionalsAction',
      'filterProfessionalsAction'
    ]),
    applyFilterProfessionalsAction() {
      const allIsNUl = Object.values(this.payloadFilter).every(el => el === '')
      if (allIsNUl) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Warning',
              icon: 'CheckIcon',
              text: 'Veuillez sélectionner au moins un champ du filtre.',
              variant: 'warning',
            },
          },
          {
            position: 'top-center',
          },
        )
      } else {
        this.isReLoadFilterProfessionals = true
        this.rows = []
        this.filterProfessionalsAction(this.payloadFilter)
          .then(response => {
            this.isReLoadFilterProfessionals = false
            this.rows = this.customTab(this.getProfessionals)
            this.total = this.rows.length
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    customTab(array) {
      const tab = []
      array.forEach(element => {
        switch (element.status) {
          case 0:
            element.setStatus = 'Non Validé'
            break
          case 1:
            element.setStatus = 'Validé'
            break
          case 2:
            element.setStatus = 'Suspendu'
            break
          case 3:
          element.setStatus = 'Candidature spontanée'
          break
          default:
            element.setStatus = null
        }
        tab.push(element)
      })
      return tab
    },
    showAssignmentModal(id) {
      this.clickedRegistrationId = id
      this.$bvModal.show('modal-pro-assignment')
    },
    hideModal() {
      this.$bvModal.hide('modal-pro-assignment')
      this.payload = {
        bdp_id: '',
      }
    },

    applyProRegistrationAssignment() {
      this.isProRegistrationAssignmentLoading = true
      this.assignProRegistrationAction({
        proId: this.clickedRegistrationId,
        payload: this.payload,
      })
        .then(async response => {
          this.applyLoadMoreProfessionalsAction(this.metaData.current_page_url)
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Succès',
                icon: 'CheckIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'top-center',
            },
          )
          this.isProRegistrationAssignmentLoading = false
          this.hideModal()
        })
        .catch(error => {
          this.isProRegistrationAssignmentLoading = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    applyLoadMoreProfessionalsAction(url) {
      this.$store.commit('SET_IS_PROFESSIONALS_LOADING', true)
      this.loadMoreProfessionalsAction(url)
        .then(() => {
          this.$store.commit('SET_IS_PROFESSIONALS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_PROFESSIONALS_LOADING', false)
        })
    },

    applyGetProfessionalsAction() {
      this.isLoading = true
      this.$store.commit('SET_IS_PROFESSIONALS_LOADING', true)
      this.rows = []

      this.getProfessionalsAction()
        .then(() => {
          this.isLoading = false
          this.$store.commit('SET_IS_PROFESSIONALS_LOADING', false)
        })
        .catch(() => {
          this.isLoading = false
          this.$store.commit('SET_IS_PROFESSIONALS_LOADING', false)
        })
    },

    applyGetBdpSavedProsAction(id) {
      this.$store.commit('SET_IS_PROFESSIONALS_LOADING', true)
      this.getBdpSavedProsAction(id)
        .then(() => {
          this.$store.commit('SET_IS_PROFESSIONALS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_PROFESSIONALS_LOADING', false)
        })
    },

    applyGetBusinessDeveloperListAction() {
      this.getBusinessDeveloperListAction().then(response => {
        response.data.forEach(item => {
          this.bdpListOptions.push({ label: item.full_name, value: item.id })
        })
      })
    },

    applyDeleteProAction(id) {
      this.$swal({
        title: 'Suppression',
        text: 'Êtes-vous sûr de vouloir supprimer ce pro?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        preConfirm: async () => {
          await this.deleteProfessionalAction(id)
          // eslint-disable-next-line no-return-await
          this.applyLoadMoreProfessionalsAction(this.metaData.current_page_url)
        },
      })
        .then(response => {
          console.log(response)
          if (response.value) {
            this.applyLoadMoreProfessionalsAction(this.metaData.current_page_url)
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'pro supprimé avec succès',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
        .catch(error => {
           ;
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    resolveProStatusVariant: status => {
      if (status === 1) return 'success'
      if (status === 2) return 'danger'
      if (status === 3) return 'info'
      return 'warning'
    },

    showProDetails(pro) {
      this.$store.commit('professionals/SET_PRO_DETAILS', pro)
      console.log(this.getProDetails)
      this.$router.push({
        name: 'admin-pros-details',
        params: { id: pro.id },
      })
    },

  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.itemActions :hover {
  background: transparent !important;
}
.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
<style lang="scss">
  .vgt-select {
  background: transparent !important;
  border-color: #404656 !important;
  }
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
