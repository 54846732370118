<template>
  <div
    v-if="
      getUser.role.slug === 'business-developer-pro' ||
        getUser.role.slug === 'super-administrateur'
    "
  >
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Search -->
        <b-col
          cols="12"
          md="12"
        >
          <div class="d-flex align-items-center justify-content-end">
            <!-- <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1 mt-auto"
              placeholder="Rechercher..."
            /> -->
            <b-form-group class="mr-auto my-auto">
              <h6>Filtrage par période</h6>
              <flat-pickr
                v-model="rangeDate"
                class="form-control"
                :config="{ mode: 'range'}"
              />
            </b-form-group>
            <!-- <v-select
              v-model="statusFilter"
              :options="statusOptions"
              class="invoice-filter-select d-inline-block mr-1"
              placeholder="Filtrer par statut"
              :reduce="(item) => item.value"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select> -->
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refInvoiceListTable"
      :items="filterIsActive ? rangeData : tableItems"
      responsive
      :busy="isPayedProsLoading"
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="Liste vide"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
      <template #table-busy>
        <div class="text-center text-info my-2">
          <b-spinner class="align-middle" /> &nbsp;
          <strong>Chargement...</strong>
        </div>
      </template>

      <!-- Column: Pro -->
      <template #cell(pro)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              rounded
              :text="avatarText(data.item.professional_full_name)"
              size="42"
              :src="data.item.professional_profile_image"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.professional_full_name }}
          </span>
          <small class="text-success font-small-3">
            {{ data.item.professional_phone_number }}</small>
        </b-media>
      </template>

      <!-- Column: Commande -->
      <template #cell(order_id)="data">
        <router-link
          :to="'/orders/'+data.item.id+'/details'"
          class="font-weight-bold d-block text-nowrap cursor-pointer"
        >
          Voir les détails de la commande
        </router-link>
      </template>

      <!-- Column: Date de reception -->
      <template #cell(date_professional_has_been_paid)="data">
        <span>
          {{ moment(data.item.date_professional_has_been_paid).locale('fr').format('dddd DD MMMM YYYY') }}
        </span>
      </template>
    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="
            d-flex
            align-items-center
            justify-content-center justify-content-sm-start
          "
        >
          <span
            class="text-muted"
          >Affichage de {{ dataMeta.from }} à {{ dataMeta.to }} sur
            {{ dataMeta.of }} lignes</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="
            d-flex
            align-items-center
            justify-content-center justify-content-sm-end
          "
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>

  </div>
  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import {
  // BTab,
  // BTabs,
  // BCard,
  BRow,
  BCol,
  BFormGroup,
  // BFormInput,
  // BButton,
  BTable,
  BMedia,
  BAvatar,
  // BLink,
  // BBadge,
  // BDropdown,
  // BDropdownItem,
  BPagination,
  // BTooltip,
  BSpinner,
  // BTabs,
  // BAlert,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
// import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    // BTab,
    // BTabs,
    // BCard,
    // BAlert,
    // BLink,
    BRow,
    BCol,
    BFormGroup,
    // BFormInput,
    // BButton,
    BTable,
    BMedia,
    BAvatar,
    // BBadge,
    // BDropdown,
    // BDropdownItem,
    BPagination,
    // BTooltip,
    BSpinner,
    // vSelect,
    flatPickr,
  },

  data() {
    return {
      tableItems: [],
      tableColumns: [
        { key: 'pro', label: 'Identité', sortable: true },
        { key: 'service_name', label: 'Service', sortable: true },
        { key: 'order_id', label: 'Commande', sortable: true },
        { key: 'professional_amount', label: 'Montant', sortable: true },
        { key: 'date_professional_has_been_paid', label: 'Date de réception du paiement', sortable: true },
      ],
      perPage: 10,
      totalItems: 0,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      searchQuery: '',
      sortBy: 'created_at',
      isSortDirDesc: true,
      statusFilter: null,
      avatarText,

      rangeDate: [],
      filterIsActive: false,
      rangeData: [],
    }
  },

  computed: {
    ...mapGetters('professionals', ['getPayedPros']),
    ...mapGetters(['isPayedProsLoading']),
    ...mapGetters('auth', ['getUser']),

    dataMeta() {
      const localItemsCount = this.$refs.refInvoiceListTable
        ? this.$refs.refInvoiceListTable.localItems.length
        : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.filterIsActive ? this.rangeData.length : this.totalItems,
      }
    },
  },

  watch: {
    getPayedPros(newValue, oldValue) {
      if (oldValue.length !== 0 && oldValue.length !== newValue.length) {
        this.refetchData()
      }
    },
    isPayedProsLoading(val) {
      if (val === false) {
        this.refetchData()
      }
    },
    statusFilter() {
      this.refetchData()
    },
    currentPage() {
      this.refetchData()
    },
    perPage() {
      this.refetchData()
    },
    searchQuery() {
      this.refetchData()
    },

    rangeDate(val) {
      if (val.split(' to ').length === 2) {
        this.applyGetRangePayedPros(val, this.getPayedPros)
      }
    },
  },

  created() {
    if (this.isPayedProsLoading === false) {
      this.refetchData()
    }
  },

  methods: {
    ...mapActions('professionals', [
      'getPayedProsAction',
    ]),

    refetchData() {
      const params = {
        q: this.searchQuery,
        perPage: this.perPage,
        page: this.currentPage,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
        status: this.statusFilter,
      }
      const { items, total } = this.getData(params)
      this.tableItems = items
      this.total = total
      this.totalItems = this.total
    },

    sortCompare: key => (a, b) => {
      const fieldA = a[key]
      const fieldB = b[key]
      let comparison = 0
      if (fieldA > fieldB) {
        comparison = 1
      } else if (fieldA < fieldB) {
        comparison = -1
      }
      return comparison
    },

    paginateArray: (array, perPage, page) => array.slice((page - 1) * perPage, page * perPage),

    getData(params) {
      const data = this.getPayedPros
      // eslint-disable-next-line object-curly-newline
      const {
        q = '',
        perPage = 10,
        page = 1,
        sortBy = 'created_at',
        sortDesc = true,
        // status = null,
      } = params
      /* eslint-enable */

      const queryLowered = q.toLowerCase()
      const filteredData = data.filter(
        item =>
          /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
          (item.professional_full_name.toLowerCase().includes(queryLowered) ||
            item.professional_phone_number.toLowerCase().includes(queryLowered) ||
            item.professional_amount.toLowerCase().includes(queryLowered)),
      )
      /* eslint-enable  */

      const sortedData = filteredData.sort(this.sortCompare(sortBy))
      if (sortDesc) sortedData.reverse()

      return {
        items: this.paginateArray(sortedData, perPage, page).reverse(),
        total: filteredData.length,
      }
    },

    applyGetRangePayedPros(val, ProsData) {
      const payload = {
        start_date: val.split(' to ')[0],
        end_date: val.split(' to ')[1],
      }
      // console.log(payload)
      // console.log(this.registeredClients)
      this.rangeData = ProsData.filter(item => {
        const paymentValidationDate = item.date_professional_has_been_paid
        // console.log(paymentValidationDate)
        return (Date.parse(paymentValidationDate) >= Date.parse(payload.start_date) && Date.parse(paymentValidationDate) <= Date.parse(payload.end_date))
      })
      // console.log(this.rangeData)
      this.filterIsActive = true
    },
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
